<script setup lang="ts">
import type { BlockConfigDetailsFragment } from '@/generated/sdk'
import { BlockConfigArgumentType } from '@/generated/sdk'
import { TwinIcon } from '@/ui/components'
import { useWorkflowDetails } from '@/workflow-edit/composables'
import { Button, Form, FormItem, Row, TextInput } from '@madxnl/dodo-ui'
import { computed, reactive, toRefs, watchEffect } from 'vue'
import BlockSettingsField from './BlockSettingsField.vue'
import { useBlockSettingsContext } from './composables'
import { useFieldGeneration } from './composables/useFieldGeneration'
import { useManageArguments } from './composables/useManageArguments'

const props = defineProps<{
  blockConfig?: BlockConfigDetailsFragment
  context: 'input' | 'output' | 'settings'
  hideFields?: string[]
}>()

const { workflow } = useWorkflowDetails()
const { setContext } = useBlockSettingsContext()
const { generatedFields } = useFieldGeneration()
const { addExtraArgument, canAddExtraArgument, isNameTaken } = useManageArguments()

const { blockConfig } = toRefs(props)

const disabled = computed(() => !workflow.value?.draft)

watchEffect(() => setContext(workflow.value, props.context, blockConfig.value))

const data = reactive({
  name: '',
})

async function clickAddArgument() {
  const argumentType = props.context === 'input' ? BlockConfigArgumentType.Reference : BlockConfigArgumentType.Constant
  await addExtraArgument(data.name, argumentType)
  data.name = ''
}

const showAddArgument = computed(() => {
  if (disabled.value) return false
  if (!canAddExtraArgument.value) return false
  return true
})
</script>

<template>
  <p v-if="!generatedFields.length" class="form-description">
    <template v-if="context === 'output'">
      Add the expected output data generated by the workflow. After running the workflow, this output will be shown in
      the results of the workflow.
    </template>
    <template v-else>No {{ context }} items yet</template>
  </p>

  <template v-else>
    <template v-for="(field, i) of generatedFields" :key="i">
      <BlockSettingsField v-if="!hideFields?.includes(field.namePath.join('.'))" :disabled="disabled" :field="field" />
    </template>
  </template>

  <Form v-if="showAddArgument" @submit="clickAddArgument">
    <hr />
    <h5>
      <template v-if="context === 'output'">Add expected output data</template>
      <template v-else> Add a new {{ context }} item </template>
    </h5>
    <FormItem>
      <Row>
        <TextInput v-model="data.name" style="flex: 1" type="text" placeholder="Enter a name" />
        <Button :disabled="data.name === '' || !isNameTaken" type="submit" square>
          <TwinIcon icon="Plus" />
        </Button>
      </Row>
    </FormItem>
  </Form>
</template>
