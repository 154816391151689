<script setup lang="ts">
import { Button, FormItem, Row, TextInput } from '@madxnl/dodo-ui'
import { onMounted } from 'vue'
import { useProfileForm } from '../composables'

const { errors, load, data, submit, saved } = useProfileForm()

onMounted(load)
</script>

<template>
  <FormItem label="Email" :error="errors.email">
    <TextInput v-model="data.email" type="email" autocomplete="email" />
  </FormItem>

  <FormItem label="First name" :error="errors.firstName">
    <TextInput v-model="data.firstName" autocomplete="given-name" />
  </FormItem>

  <FormItem label="Last name" :error="errors.lastName">
    <TextInput v-model="data.lastName" autocomplete="family-name" />
  </FormItem>

  <FormItem label="Phone number" :error="errors.phoneNumber">
    <TextInput v-model="data.phoneNumber" autocomplete="tel" />
  </FormItem>

  <Row>
    <Button color="primary" variant="solid" @click="submit">Save changes</Button>
    <p v-if="saved" class="dodo-color-success">Password changed successfully.</p>
  </Row>
</template>
