<script lang="ts" setup>
import { Button, Column, FormItem, Modal, Row, TextInput } from '@madxnl/dodo-ui'
import { computed, ref } from 'vue'
import { TwinIcon } from '.'
import { useConfirmDelete } from '../composables'

const { currentDialog } = useConfirmDelete()

const deleteConfirm = ref('')
const deleteActive = computed(() => {
  if (!currentDialog.superSecure) return true
  return deleteConfirm.value === 'DELETE'
})
</script>

<template>
  <Modal :open="currentDialog.open" gap="l" @close="currentDialog.resolve!(false)">
    <Column gap="l">
      <Row>
        <h2 style="flex: 1">Delete {{ currentDialog.type }}</h2>
        <Button variant="link" square @click="currentDialog.resolve!(false)">
          <TwinIcon icon="Cross" />
        </Button>
      </Row>

      <p>
        Are you sure you want to delete
        {{ currentDialog.name ? `${currentDialog.type} "${currentDialog.name}"` : `this ${currentDialog.type}` }}?
        <br />
        <b>This action cannot be undone.</b>
      </p>

      <Column v-if="currentDialog.superSecure">
        <FormItem label="Type 'DELETE' to confirm">
          <TextInput v-model="deleteConfirm" autofocus />
        </FormItem>
      </Column>

      <Column>
        <Button :disabled="!deleteActive" color="danger" variant="solid" @click="currentDialog.resolve!(true)">
          <TwinIcon icon="Delete" />
          Delete
        </Button>
        <Button @click="currentDialog.resolve!(false)"> Cancel </Button>
      </Column>
    </Column>
  </Modal>
</template>
