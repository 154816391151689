import { useApiClient } from '@/api'
import { getVar } from '@/environment'
import type { OAuthConnectionsQuery, OAuthProvidersQuery } from '@/generated/sdk'
import { useConfirmDelete, useSimpleMessage } from '@/ui/composables'
import type { Ref } from 'vue'
import { ref } from 'vue'

const redirectUri = getVar('VITE_APP_URL') + '/user/organization?page=integrations'

export type Provider = OAuthProvidersQuery['oAuthProviders'][0]
export type OAuthToken = OAuthConnectionsQuery['oAuthToken'][0]

export function useIntegrations() {
  const { client } = useApiClient()
  const { showMessage } = useSimpleMessage()
  const { confirmDelete } = useConfirmDelete()

  const providers: Ref<Provider[] | null> = ref(null)
  const connections: Ref<OAuthToken[] | null> = ref(null)

  async function init() {
    // Get the providers first
    const providersRes = await client.oAuthProviders()
    providers.value = providersRes.oAuthProviders

    // Handle possible redirect
    await handleRedirect()

    // Get the connections, since the redirect might have added a new one
    const connectionsRes = await client.oAuthConnections()
    connections.value = connectionsRes.oAuthToken
  }

  async function handleRedirect() {
    const urlParams = new URLSearchParams(location.search)
    const providerKey = urlParams.get('state')
    if (providerKey) {
      const provider = providers.value!.find((p) => p.provider == providerKey)
      if (!provider) {
        return
      }

      const code = urlParams.get('code')
      if (!code) {
        return
      }

      window.history.replaceState({}, document.title, window.location.pathname)

      try {
        await client.connectIntegration({
          data: {
            code,
            provider: provider.provider,
            redirectUri,
          },
        })

        showMessage('Integration connected')
      } catch (e: any) {
        if (e.message.startsWith('#0503')) {
          // This is a known error, the user has already connected this provider. Clearly communicate this to the user.
          showMessage(e.message.split(':')[0], { type: 'danger', duration: 10000 })
        } else {
          throw e
        }
      }
    }
  }

  async function deleteConnection(connection: OAuthToken) {
    if (await confirmDelete({ name: connection.name ?? connection.provider, type: 'connection' })) {
      await client.deleteOAuthConnection({ id: connection.id })
      showMessage('Connection removed')
      connections.value = connections.value!.filter((c) => c.id !== connection.id)
    }
  }

  function authorizeUrl(provider: Provider) {
    const url = new URL(provider.authorizationUrl)
    url.searchParams.append('client_id', provider.clientId)
    url.searchParams.append('redirect_uri', redirectUri)
    url.searchParams.append('response_type', provider.responseType)
    if (provider.accessType) url.searchParams.append('access_type', provider.accessType)
    if (provider.scopes) url.searchParams.append('scope', provider.scopes.join(' '))
    url.searchParams.append('state', provider.provider)
    return url.toString()
  }

  return { providers, connections, authorizeUrl, deleteConnection, init }
}
