<script setup lang="ts">
import type { BlockConfigFragment, WorkflowItemFragment } from '@/generated/sdk'
import { DisplayDate, RelatedObjectField } from '@/ui/components'
import { useEditorLinks, useWorkflowDetails } from '@/workflow-edit'
import { useWorkflows } from '@/workflows/overview/useWorkflows'
import { FormItem, Row } from '@madxnl/dodo-ui'
import { computed } from 'vue'
import { useRouter } from 'vue-router'

const { showMore, workflows, search, createNew } = useWorkflows()
const { workflow, updateBlockConfig } = useWorkflowDetails()
const router = useRouter()
const { linkSubworkflowEdit } = useEditorLinks()

const props = defineProps<{
  blockConfig: BlockConfigFragment
}>()

const selectedWorkflow = computed(() => {
  return workflows.value?.find((w) => w.id === props.blockConfig.workflow?.id) || null
})

async function goToItem(subworkflow: WorkflowItemFragment) {
  await router.push(linkSubworkflowEdit(workflow.value!.id, subworkflow.id))
}

async function newSubworkflow() {
  // Create a new workflow and set it as the subworkflow, then navigate to it
  if (!workflow.value) return
  const newWorkflow = await createNew()
  await updateBlockConfig(props.blockConfig, { workflow: newWorkflow })
  await goToItem(newWorkflow)
}

async function update(workflow: WorkflowItemFragment | null) {
  await updateBlockConfig(props.blockConfig, { workflow })
}
</script>

<template>
  <FormItem label="Subworkflow">
    <RelatedObjectField
      v-model:search="search"
      :model-value="selectedWorkflow"
      placeholder="Subworkflow"
      :items="workflows"
      :show-more="showMore"
      :get-current-text="(item) => item.name"
      :action-edit="goToItem"
      :action-create="newSubworkflow"
      :disabled="!workflow?.draft"
      @update:model-value="update"
    >
      <template #item="{ item }">
        <Row>
          <h4>{{ item.name }}</h4>
          <span class="dodo-fade-secondary"><DisplayDate :date="+item.createdAt" /></span>
        </Row>
      </template>
    </RelatedObjectField>
  </FormItem>
</template>
