import { useApiClient } from '@/api'
import type { WorkflowBlockItemFragment } from '@/generated/sdk'
import { WorkflowBlockType } from '@/generated/sdk'
import { useConfirmDelete } from '@/ui/composables'
import { computed, ref } from 'vue'
import type { EditorBlockExposed } from '../block/EditorBlock.vue'
import { useBlockTypes } from './useBlockTypes'
import { useEditorState } from './useEditorState'
import { useWorkflowDetails } from './useWorkflowDetails'
// TODO: Add ErrorCollection again when API is ready
// import {  WorkflowErrorCollectionFragment } from '@/generated/sdk'

const gridSize = 32 // Size of the grid in pixels

const editorBlocks = ref<EditorBlockExposed[]>([])

export function useWorkflowEditor() {
  const { client } = useApiClient()
  const { workflow, deleteBlock } = useWorkflowDetails()
  const { getBlockTypeDetails, fetchBlockType, fetchBlockTypeByName } = useBlockTypes()
  const { getState, setState } = useEditorState()
  const { confirmDelete } = useConfirmDelete()

  const selectedBlock = computed({
    get() {
      return workflow.value?.workflowBlocks.find((block) => block.id === selectedBlockId.value)
    },
    set(block?: WorkflowBlockItemFragment) {
      selectedBlockId.value = block ? block.id : null
    },
  })

  const selectedBlockId = computed({
    get: () => String(getState('selectedBlockId') ?? '') || null,
    set: (value) => setState(value, 'selectedBlockId'),
  })

  const selectedBlockTypeDetails = computed(() => getBlockTypeDetails(selectedBlock.value?.blockConfig.block))

  function getBlockPosition(id: string): [number, number] {
    const position = getState(['blockPositions', id])
    return Array.isArray(position) ? (position as [number, number]) : [0, 0]
  }

  function setBlockPosition(id: string, [x, y]: [number, number]) {
    // Round to 2 decimal places
    const rounded = [Math.round(x * 100) / 100, Math.round(y * 100) / 100]
    setState(rounded, 'blockPositions', id)
    cleanup()
  }

  function cleanup() {
    // Clean up old positions from the json
    const workflowBlockIds = workflow.value?.workflowBlocks.map((b) => b.id) ?? []
    const positions = getState('blockPositions') ?? {}
    for (const key in positions) {
      if (!workflowBlockIds.includes(key)) {
        delete positions[key as keyof typeof positions]
      }
    }
  }

  function getBlock(blockId: string) {
    return workflow.value?.workflowBlocks.find((block) => block.id === blockId) ?? null
  }

  const selectedBlockName = computed(() => {
    return selectedBlock.value?.name || selectedBlockTypeDetails.value.readableName
  })

  function getPreviousBlocks(block: { previousBlocks?: { id: string }[] | null }) {
    return block?.previousBlocks?.map(({ id }) => getBlock(id)!).filter(Boolean) ?? []
  }

  function getNextBlocks(block: { nextBlocks?: { id: string }[] | null }) {
    return block?.nextBlocks?.map(({ id }) => getBlock(id)!).filter(Boolean) ?? []
  }

  async function addNewBlock(
    args: {
      nextBlocks?: { id: string }[]
      previousBlocks?: { id: string }[]
      condition?: string
      pos?: [number, number]
      blockType?: WorkflowBlockType
      blockConfigBlock?: string
    },
    opts: { select: boolean },
  ) {
    const block = args.blockConfigBlock ?? (await fetchBlockTypeByName('prompt')).id
    const name = await newBlockName(block)
    const response = await client.createWorkflowBlock({
      input: {
        name,
        blockType: args.blockType ?? WorkflowBlockType.Normal,
        condition: args.condition,
        blockConfig: {
          block,
          arguments: [],
          runs: [],
        },
        workflow: { id: workflow.value!.id },
        nextBlocks: args.nextBlocks,
        previousBlocks: args.previousBlocks,
      },
    })
    const newBlock = response.createWorkflowBlock
    workflow.value?.workflowBlocks.push(newBlock)

    addConnections(newBlock)
    setNewBlockPosition(newBlock, args.pos)

    if (opts.select) selectedBlock.value = newBlock
    return newBlock
  }

  function addConnections(block: WorkflowBlockItemFragment) {
    // Made sure the block is properly connected to the previous and next blocks
    for (const { id } of block.nextBlocks ?? []) {
      const next = getBlock(id)
      if (!next || id === block.id) continue
      next.previousBlocks ??= []
      next.previousBlocks.push({ id: block.id, condition: next.condition })
    }
    for (const { id } of block.previousBlocks ?? []) {
      const prev = getBlock(id)
      if (!prev || id === block.id) continue
      prev.nextBlocks ??= []
      prev.nextBlocks.push({ id: block.id, condition: block.condition })
    }
  }

  function setNewBlockPosition(newBlock: WorkflowBlockItemFragment, pos?: [number, number]) {
    const prevBlockId = newBlock.previousBlocks?.[0]?.id
    const nextBlockId = newBlock.nextBlocks?.[0]?.id
    const prevBlock = prevBlockId ? getBlock(prevBlockId) : null
    const prevBlockPosition = prevBlockId ? getBlockPosition(prevBlockId) : null
    const nextBlockPosition = nextBlockId ? getBlockPosition(nextBlockId) : null
    const parentIsSwitch = prevBlock?.blockType === WorkflowBlockType.Switch
    const parentIsIfElse = prevBlock?.blockType === WorkflowBlockType.IfElse
    const parentIsLoop = prevBlock?.blockConfig?.loop != null
    const S = gridSize
    let gridXY: [number, number] = pos ?? [0, 0]
    if (prevBlockPosition) {
      const [px, py] = prevBlockPosition
      if (prevBlockPosition && parentIsLoop) {
        gridXY = [px, py + 10 * S]
      } else if (prevBlockPosition && parentIsSwitch) {
        gridXY = [px + 20 * S, py + 4 * S]
      } else if (prevBlockPosition && parentIsIfElse) {
        gridXY = [px + (newBlock.condition === 'true' ? -8 : 8) * S, py + 8 * S]
      } else if (prevBlockPosition) {
        gridXY = [px, py + 6 * S]
      }
    } else if (nextBlockPosition) {
      const [nx, ny] = nextBlockPosition
      gridXY = [nx, ny - 6 * S]
    }
    // Shift position if the new block is too close to another block
    for (let i = 0; i < 20; ++i) {
      const tooClose = editorBlocks.value.some((b) => {
        const [dx, dy] = [gridXY[0] - b.position[0], gridXY[1] - b.position[1]]
        return dx * dx + dy * dy < S * S
      })
      if (!tooClose) break
      gridXY = [gridXY[0] + 1, gridXY[1] + 1]
    }

    setBlockPosition(newBlock.id, gridXY)
  }

  async function removeBlock(block: WorkflowBlockItemFragment) {
    if (!workflow.value?.draft) return

    const confirmed = await confirmDelete({ name: block.name, type: 'block' })
    if (!confirmed) return
    await deleteBlock(block)
    // Clear selected block if it was removed
    if (selectedBlock.value?.id === block.id) selectedBlock.value = undefined
  }

  function getBlockOutputConditions(block: WorkflowBlockItemFragment) {
    const nextBlocks = getNextBlocks(block)
    const conditionsSet = new Set(nextBlocks?.map((b) => b.condition))
    const conditions = Array.from(conditionsSet)
    conditions.sort((a, b) => (a === 'true' ? -1 : a === 'false' ? 1 : `${a}`.localeCompare(`${b}`)))
    return conditions
  }

  async function newBlockName(block: string) {
    const blockName = (await fetchBlockType(block))?.readableName // Ensure the block type is fetched
    let n = 1
    for (let i = 0; i < 99 && checkNameExists(`${blockName} ${n}`); i++) {
      n += 1
    }
    return `${blockName} ${n}`
  }

  function checkNameExists(name: string, blockId?: string) {
    return workflow.value?.workflowBlocks.some((block) => block.name === name && block.id !== blockId)
  }

  // TODO: Add ErrorCollection again when API is ready
  // const validationResult = ref<WorkflowErrorCollectionFragment>()
  //
  // async function queryWorkflowErrors() {
  //   const workflowId = workflow.value!.id
  //   const response = await client.validateWorkflowConfig({ workflowId })
  //   validationResult.value = response.validateWorkflowConfig
  //   return validationResult.value
  // }

  return {
    addNewBlock,
    getBlockPosition,
    setBlockPosition,
    selectedBlock,
    removeBlock,
    getBlock,
    getBlockTypeDetails,
    selectedBlockName,
    getPreviousBlocks,
    getNextBlocks,
    getBlockOutputConditions,
    editorBlocks,
    gridSize,
    checkNameExists,
  }
}
