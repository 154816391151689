<script lang="ts" setup>
import { Button, Column, Row } from '@madxnl/dodo-ui'
import { ref } from 'vue'

const isSupported = featureModalSupported()
const closed = ref(false)

function featureModalSupported() {
  return 'open' in document.createElement('dialog')
}
</script>

<template>
  <div v-if="!closed && !isSupported" :class="$style.simplemodal">
    <div :class="$style.content">
      <Column padding="l">
        <h2>Browser is out of date</h2>
        <p>
          Your browser appears to be out of date and may not work correctly with this application. Please use an
          up-to-date version of Chrome for the best experience. If you continue to experience issues, please contact
          support.
        </p>
        <br />
        <Row justify="end">
          <Button @click="closed = true">Continue</Button>
        </Row>
      </Column>
    </div>
  </div>
</template>

<style module>
.simplemodal {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  z-index: 1000;
}
.content {
  background: white;
  border-radius: 8px;
  max-width: 600px;
  width: 100%;
}
</style>
