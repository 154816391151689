<script setup lang="ts">
import { CheckMark, TwinIcon } from '@/ui/components'
import { useConfirmDelete, useSimpleMessage, useUtils } from '@/ui/composables'
import { useWorkflowDetails } from '@/workflow-edit/composables'
import { workflowLinks } from '@/workflows/routes'
import { Button, Column, Dropdown, FormItem, Tabs, TextInput, Textarea } from '@madxnl/dodo-ui'
import { computed, ref } from 'vue'
import { useRouter } from 'vue-router'
import { BlockSettings } from '../block-sidebar'
import { ExposeWorkflowModal, WorkflowSettings } from './'
import WorkflowApiCall from './WorkflowApiCall.vue'

const { confirmDelete } = useConfirmDelete()
const { showMessage } = useSimpleMessage()
const { workflow, updateWorkflow, deleteWorkflow } = useWorkflowDetails()
const { debounce } = useUtils()
const router = useRouter()

const workflowSettingsTabs = [
  { key: 'general' as const, name: 'General', slot: 'general' },
  { key: 'input' as const, name: 'Input', slot: 'input' },
  { key: 'output' as const, name: 'Output', slot: 'output' },
]

const openExposeWorkflowModal = ref(false)

const publicLink = computed(() => {
  const route = router.resolve(workflowLinks.runWorkflow(workflow.value!.id))
  return `${window.location.origin}${route.href}`
})

async function handleClickExposeWorkflow() {
  if (workflow.value?.isPublic) {
    await updateWorkflow({ isPublic: false })
  } else {
    openExposeWorkflowModal.value = true
  }
}

async function handleCopyLink() {
  if (!publicLink.value) return
  await navigator.clipboard.writeText(publicLink.value)
  showMessage('Link copied to clipboard')
}

const setWorkflowNameDesc = debounce(async () => {
  const name = workflow.value?.name
  const description = workflow.value?.description
  await updateWorkflow({ description, name })
})

async function clickDelete() {
  if (!workflow.value) return
  if (await confirmDelete({ name: workflow.value.name, type: 'workflow', superSecure: true })) {
    await deleteWorkflow()
    showMessage('Workflow deleted')
    await router.push(workflowLinks.workflows())
  }
}
</script>
<template>
  <template v-if="workflow">
    <Tabs :tabs="workflowSettingsTabs" style="flex-grow: 1">
      <template #general>
        <FormItem label="Name">
          <TextInput
            v-model="workflow.name"
            name="name"
            :placeholder="workflow.name"
            :disabled="!workflow?.draft"
            @update:model-value="setWorkflowNameDesc.trigger"
          />
        </FormItem>

        <FormItem label="Description">
          <Textarea
            v-model="workflow.description"
            :min-rows="2"
            :max-rows="6"
            placeholder="Add a description"
            :disabled="!workflow.draft"
            @update:model-value="setWorkflowNameDesc.trigger"
          />
        </FormItem>

        <hr />

        <Column>
          <FormItem label="Access to workflow">
            <CheckMark :disabled="!workflow.draft" :checked="!!workflow.isPublic" @toggle="handleClickExposeWorkflow">
              Publicly accessible
            </CheckMark>
          </FormItem>

          <Dropdown>
            <template #trigger="{ toggle }">
              <FormItem label="Run workflow URL">
                <Button @click="toggle">
                  <TwinIcon icon="Eye" />
                  Show URL
                </Button>
              </FormItem>
            </template>
            <template #content="{ close }">
              <Column>
                <h3>Run workflow URL</h3>
                <div :class="$style.code">
                  <pre><code>{{ publicLink }}</code></pre>
                </div>
                <Button color="primary" @click="handleCopyLink().then(close)">
                  <TwinIcon icon="Copy" />
                  Copy to clipboard
                </Button>
              </Column>
            </template>
          </Dropdown>
        </Column>

        <hr />

        <Column>
          <WorkflowApiCall />
        </Column>

        <Column gap="l" style="margin-top: auto">
          <hr />
          <Button variant="solid" :disabled="!workflow.draft" color="danger" @click="clickDelete">
            <TwinIcon icon="Delete" />
            Delete workflow
          </Button>
        </Column>
      </template>
      <template #input>
        <WorkflowSettings />
      </template>

      <template #output>
        <BlockSettings context="output" />
      </template>
    </Tabs>

    <ExposeWorkflowModal v-model="openExposeWorkflowModal" />
  </template>
</template>

<style module>
.code {
  background: var(--grey-1-altbg);
  border: 1px solid var(--grey-2-lines);
  border-radius: 8px;
}

.code pre {
  width: 568px;
  margin: 0;
  padding: 8px;
  font-size: 14px;
}
</style>
