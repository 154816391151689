<script setup lang="ts">
import { SidebarLayout } from '@/ui/components'
import { useAnimationFrame } from '@/ui/composables'
import { computed, ref, watchEffect } from 'vue'
import EditorRunErrorStatus from './EditorRunErrorStatus.vue'
import EditorZoomControls from './EditorZoomControls.vue'
import { BlockConnection, EditorBlock } from './block'
import { useEditorShortcut, useEditorState, useEditorView, useWorkflowDetails, useWorkflowEditor } from './composables'
import { DragConnection } from './connect'
import { EditorContainer } from './container'
import { SidebarBlocksInsert } from './sidebar-left'
import { EditorSidebarRight } from './sidebar-right'

const { containerRect } = useEditorView()
const { editorBlocks, selectedBlock } = useWorkflowEditor()
const { workflow } = useWorkflowDetails()
const { onAnimationFrame } = useAnimationFrame()
const { enableGlobalShortcuts } = useEditorShortcut()
const { getState, setState } = useEditorState()

const sidebarLeftCollapsed = computed({
  get: (): boolean => !!getState('sidebarLeftCollapsed'),
  set: (value) => setState(value, 'sidebarLeftCollapsed'),
})

const sidebarRightCollapsed = computed({
  get: (): boolean => !!getState('sidebarRightCollapsed'),
  set: (value) => setState(value, 'sidebarRightCollapsed'),
})

enableGlobalShortcuts()

const blocks = ref<typeof editorBlocks.value>([])
const container = ref<InstanceType<typeof EditorContainer>>()

// Update the editor container area for line drawing etc
onAnimationFrame(() => {
  const rect = container.value?.el?.getBoundingClientRect()
  const { top, left, width, height } = rect ?? { top: 0, left: 0, width: 0, height: 0 }
  containerRect.value = { top, left, width, height }
})

watchEffect(() => (editorBlocks.value = blocks.value))
</script>

<template>
  <SidebarLayout
    v-if="workflow"
    v-model:left-collapsed="sidebarLeftCollapsed"
    v-model:right-collapsed="sidebarRightCollapsed"
  >
    <EditorContainer ref="container">
      <template v-for="workflowBlock in workflow.workflowBlocks">
        <template v-for="next in workflowBlock.nextBlocks" :key="`${workflowBlock.id}-${next.id}`">
          <BlockConnection :workflow-block="workflowBlock" :next-block-id="next.id" :disabled="!workflow.draft" />
        </template>
      </template>

      <DragConnection />

      <template v-for="workflowBlock in workflow.workflowBlocks" :key="workflowBlock.id">
        <EditorBlock ref="blocks" :workflow-block="workflowBlock" :disabled="!workflow.draft" />
      </template>
    </EditorContainer>

    <EditorZoomControls />
    <EditorRunErrorStatus />

    <template #sidebar-left-title>Blocks</template>
    <template #sidebar-left>
      <SidebarBlocksInsert :disabled="!workflow?.draft" />
    </template>

    <template #sidebar-right-title>
      <template v-if="selectedBlock">Block</template>
      <template v-else>Workflow</template>
    </template>
    <template #sidebar-right>
      <EditorSidebarRight />
    </template>
  </SidebarLayout>
</template>
