<script setup lang="ts">
import { useApiClient } from '@/api'
import type { BlockConfigDetailsFragment, WorkflowBlockItemFragment } from '@/generated/sdk'
import { BlockConfigArgumentType } from '@/generated/sdk'
import { useBlockTypes, useWorkflowDetails } from '@/workflow-edit/composables'
import { FormItem, Select, Spinner } from '@madxnl/dodo-ui'
import { computed, watch } from 'vue'
import { useDataSources, useManageArguments } from './composables'

const props = defineProps<{
  workflowBlock: WorkflowBlockItemFragment
}>()

const { client } = useApiClient()
const { blockTypes, fetchBlockTypeByName } = useBlockTypes()
const { workflow } = useWorkflowDetails()
const { getAvailableDataSources } = useDataSources()
const { saveArgument } = useManageArguments()
const { updateBlockConfig } = useWorkflowDetails()

const blockConfig = computed(() => props.workflowBlock.blockConfig)
const isLoopBlock = computed(() => blockConfig.value.block.includes('loop'))
const innerConfig = computed(() => blockConfig.value.loop?.blockConfig)
const listArgument = computed(() => blockConfig.value?.arguments.find((x) => x.name === 'input'))
const availableBlockResults = computed(() => getAvailableDataSources(props.workflowBlock, { excludeLoopEntry: true }))
const hasListAndInnerConfig = computed(() => !!listArgument.value && !!innerConfig.value)
const needsConfiguring = computed(() => isLoopBlock.value !== hasListAndInnerConfig.value)

watch(needsConfiguring, configureBlockForLoop, { immediate: true })

async function configureBlockForLoop() {
  if (!needsConfiguring.value) return
  if (isLoopBlock.value) {
    if (!listArgument.value) await createLoopArgument()
    if (!innerConfig.value) await createLoopConfig()
  } else if (blockConfig.value.loop) {
    await deleteLoop(blockConfig.value)
  }
}

async function createLoopArgument() {
  const result = await client.createBlockConfigArgument({
    input: {
      name: 'input',
      argumentType: BlockConfigArgumentType.Reference,
      value: '',
      blockConfig: { id: blockConfig.value.id },
    },
  })
  blockConfig.value.arguments.push(result.createBlockConfigArgument)
}

async function createLoopConfig() {
  const result = await client.updateBlockConfig({
    input: {
      id: blockConfig.value.id,
      inheritContext: true,
      loop: {
        id: blockConfig.value.loop?.id,
        blockConfig: {
          block: (await fetchBlockTypeByName('prompt')).id,
          arguments: [],
        },
      },
    },
  })
  blockConfig.value.inheritContext = result.updateBlockConfig.inheritContext
  blockConfig.value.loop = result.updateBlockConfig.loop
}

async function deleteLoop(config: BlockConfigDetailsFragment) {
  await client.deleteLoop({ loopId: config.loop!.id })
  config.loop = null
}

async function selectListArgument(value: string) {
  if (listArgument.value?.value === value) return
  await saveArgument('input', listArgument.value ?? null, { value })
}

async function selectLoopBlockType(block: string) {
  const config = innerConfig.value
  if (!config || config.block === block) return
  await updateBlockConfig(config, { block })
}
</script>

<template>
  <template v-if="isLoopBlock">
    <h4>Loop block settings</h4>

    <FormItem label="List" description="Select a list to loop over">
      <Select
        v-if="listArgument"
        :model-value="listArgument.value"
        :options="availableBlockResults.flatMap((g) => g.references)"
        :disabled="!workflow?.draft"
        @update:model-value="selectListArgument"
      />
      <Spinner v-else />
    </FormItem>

    <FormItem v-if="innerConfig" label="Loop block">
      <Select
        v-if="blockTypes"
        :model-value="innerConfig.block"
        :disabled="!workflow?.draft"
        :options="blockTypes.filter((x) => !x.id.includes('loop')).map((b) => ({ label: b.readableName, value: b.id }))"
        @update:model-value="selectLoopBlockType"
      />
      <Spinner v-else />
    </FormItem>
  </template>
</template>
