<script setup lang="ts">
import { MainPageLayout } from '@/navigation/components'
import { Card, Tabs } from '@madxnl/dodo-ui'
import { computed, onMounted, ref } from 'vue'
import { ChargebeeInvoices, OrganizationClients, OrganizationForm, OrganizationMembers } from '.'
import { useUserOrganization } from '../composables'
import OrganizationApi from './OrganizationApi.vue'
import OrganizationIntegrations from './OrganizationIntegrations.vue'

const { organization, fetch } = useUserOrganization()

const basicTabs = [
  { name: 'Settings', slot: 'settings' },
  { name: 'Members', slot: 'members' },
  { name: 'API Keys', slot: 'api' },
  { name: 'Integrations', slot: 'integrations' },
]

const tabs = computed(() => {
  if (!organization.value) return []
  if (organization.value.organizations.length > 0) {
    return [...basicTabs, { name: 'Clients', slot: 'clients' }]
  }
  return basicTabs
})

const tabIndex = ref(0)

onMounted(async () => {
  await fetch()
  const urlParams = new URLSearchParams(location.search)
  const page = urlParams.get('page')
  if (page) {
    const tab = basicTabs.find((tab) => tab.slot === page)
    if (tab) {
      tabIndex.value = basicTabs.indexOf(tab)
    }
  }
})
</script>

<template>
  <MainPageLayout>
    <h1>Your organization</h1>

    <Tabs v-model:tab-index="tabIndex" :tabs="tabs" gap="l">
      <template #settings>
        <Card>
          <h3>General</h3>
          <OrganizationForm />
        </Card>

        <Card>
          <h3>Subscription</h3>
          <ChargebeeInvoices />
        </Card>
      </template>

      <template #members>
        <OrganizationMembers />
      </template>

      <template #api>
        <OrganizationApi />
      </template>

      <template #integrations>
        <OrganizationIntegrations />
      </template>

      <template #clients>
        <OrganizationClients />
      </template>
    </Tabs>
  </MainPageLayout>
</template>
