import { useApiClient } from '@/api'
import type { BlockConfigArgumentFragment, BlockConfigArgumentInputSchema } from '@/generated/sdk'
import { BlockConfigArgumentType } from '@/generated/sdk'
import { computed, ref, watchEffect } from 'vue'
import { useBlockSettingsContext } from './useBlockSettingsContext'

export function useManageArguments() {
  const { client } = useApiClient()
  const { workflow, blockConfig, blockType, mode } = useBlockSettingsContext()

  const configArgs = ref<BlockConfigArgumentFragment[]>([])

  watchEffect(() => {
    if (mode.value === 'output') {
      configArgs.value = workflow.value?.result ?? []
    } else {
      // settings
      configArgs.value = blockConfig.value?.arguments ?? []
    }
  })

  const createArgSettings = computed(() => {
    if (blockConfig.value) return { blockConfig: { id: blockConfig.value.id } }
    return { workflow: { id: workflow.value!.id } }
  })

  async function createArgument(input: BlockConfigArgumentInputSchema) {
    const result = await client.createBlockConfigArgument({ input })
    const newArg = result.createBlockConfigArgument
    configArgs.value.push(newArg)
    return newArg
  }

  async function saveArgument(
    name: string,
    argument: BlockConfigArgumentFragment | null,
    input: {
      argumentType?: BlockConfigArgumentType
      value?: string
    },
  ) {
    if (argument) {
      if (argument.name !== name) throw new Error('Cannot change the name of an argument')
      Object.assign(argument, input)
      await client.updateBlockConfigArgument({ input: argument })
    } else {
      const argumentType = BlockConfigArgumentType.Constant
      const blockTypeArg = blockType.value?.arguments.find((a) => a.name === name)
      const value = String(blockTypeArg?.defaultValue ?? '')
      await createArgument({ name, argumentType, value, ...createArgSettings.value, ...input })
    }
  }

  async function renameArgument(argument: BlockConfigArgumentFragment, name: string) {
    if (argument.name === name) return
    argument.name = name
    await client.updateBlockConfigArgument({ input: { id: argument.id, name } })
  }

  async function deleteArgument(id: string) {
    await client.deleteBlockConfigArgument({ id })
    const idx = configArgs.value.findIndex((a) => a.id === id)
    if (idx >= 0) configArgs.value.splice(idx, 1)
  }

  async function addExtraArgument(name: string, argumentType: BlockConfigArgumentType) {
    await createArgument({ name, argumentType, value: name, ...createArgSettings.value })
  }

  // Checks if the name of the new argument is not already taken
  const isNameTaken = (name: string) => computed(() => name && !configArgs.value.find((a) => a.name === name))

  const canAddExtraArgument = computed(() => {
    // If no block is selected, we are in the workflow.result. We can always add extra arguments.
    if (!blockConfig.value) return true
    // Only if the block has dynamic arguments, we can add extra arguments.
    return blockType.value?.dynamicArguments || false
  })

  return {
    configArgs,
    saveArgument,
    renameArgument,
    deleteArgument,
    addExtraArgument,
    isNameTaken,
    canAddExtraArgument,
  }
}
